import store from "@storefront/core/store";
import menu from "@/base/store/modules/menu";
import home from "@/base/store/modules/home";
// import sale from "@/base/store/modules/sale";
import mobileRes from "@/base/store/modules/mobileRes";
import compare from "@/base/store/modules/compare";
import product from "@/base/store/modules/product";
import category  from "@/esf_antarctica_feestenkleding/store/modules/category";

store.registerModule("menu", menu);
store.registerModule("home", home);
// store.registerModule("sale", sale);
store.registerModule("mobileRes", mobileRes);
store.registerModule("compare", compare);
store.registerModule("product", product);
store.registerModule("category", category);

export default store;
